<template>
  <Section>
    <Container>
      <div class="title-bar margin-bottom">
        <h2>{{ pageTitle }} <span>({{ trials?.length }}&nbsp;essais et {{ selectedVariables.length
            }}&nbsp;variables)</span></h2>
      </div>
      <Input class="capped-width" v-if="grouping" label="Nom du regroupement" v-model="grouping.nom" id="name" required
        :text-error="errors.nom" />
      <Radio class="capped-width" v-if="grouping" label="Type d'analyse" v-model="grouping.type" id="type" required
        :text-error="errors.type" :items="[
          { label: 'Rapport en données brutes', value: 1 },
          { label: 'Rapport en données moyennées', value: 2 }
        ]" @update:modelValue="setType($event)" inputStyle="inline" />
    </Container>
  </Section>

  <Section>
    <Container>
      <h3>{{ trials?.length }}&nbsp;essai{{ trials?.length > 1 ? 's' : '' }}</h3>
    </Container>
    <Container>
      <List :listData="trials" :header="[{ label: 'Nom de l\'essai' },
      { label: 'Année' },
      { label: 'Culture' },
      { label: 'Entité' },
      { label: 'Modalités' },
      { label: 'Variables' }]" :items="['nom', 'annee', 'cultures', 'entite', 'modalites', 'variables']"
        disableCheckbox disableActions>
        <template v-slot:cultures="{ item }">
          <template v-if="item?.cultures.length > 0">
            <div class="tags">
              <MiniTag v-for="culture in item?.cultures" :key="culture.id" :id="culture.uid" :text="culture.nom" />
            </div>
          </template>
          <template v-else>
            <span></span>
          </template>
        </template>

        <template v-slot:annee="{ item }">
          <template v-if="item?.annee">
            {{ item?.annee?.valeur }}
          </template>
          <template v-else>
            <span></span>
          </template>
        </template>

        <template v-slot:variables="{ item }">
          <template v-if="item.variables.length > 0" >

            <template v-for="(variable, i) in item.variables" :key="i">
                <span class="variable">{{ variable?.designation }}</span>&nbsp;
                <span class="color-gray-lighty">({{ variable?.cible?.valeur }})</span>,
            </template>

          </template>
          <template v-else >
            -
          </template>
        </template>

      </List>
    </Container>
  </Section>

  <Section>
    <Container>
      <h3>{{ selectedVariables.length }}&nbsp;variable{{ selectedVariables?.length > 1 ? 's' : '' }}</h3>
      <div class="grid  capped-width">
        <template v-for="(variable, i) in selectedVariables" :key="i">
          <div>
            <div>{{ variable?.notation }}</div>
            <div class="variable">{{ variable?.designation }}</div>
            <div class="color-gray-lighty">{{ variable?.cible?.valeur }}</div>
          </div>
        </template>
      </div>
    </Container>
  </Section>
  <Loader :active="isLoading" />

</template>

<script>
import Container from '@/components/layout/Container.vue'
import Section from '@/components/layout/Section.vue'
import Input from '@/components/form/Input.vue'
import List from '@/components/list/List.vue'
import MiniTag from '@/components/base/MiniTag.vue'
import Radio from '@/components/form/Radio.vue'
import Loader from '@/components/layout/Loader.vue'

export default {
  name: 'GroupingContentAddEditView',
  components: {
    Container,
    Section,
    Input,
    List,
    MiniTag,
    Loader,
    Radio,
  },

  props: {
    pageTitle: {
      type: String,
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
    groupingProp: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      errors: {},
      trials: [],
      modalities: [],
      variables: [],
      selectedVariables: [],
      grouping: this.groupingProp,
      isLoading: false,
      routeParams: {
        id: this.$route.params.id,
        rid: this.$route.params.rid,
      },
      list: {
        headers: [
          { label: 'Nom de l\'essai' },
          { label: 'Année' },
          { label: 'Culture' },
          { label: 'Entité' },
          { label: 'Modalités' },
          { label: 'Variables' },
        ],
        col: ['nom', 'annee', 'culture', 'entite', 'modalites', 'variables'],
        key: 'id',
      },
    }
  },
  emits: ['set-step', 'set-route', 'update:modelValue', 'set-grouping'],
  watch: {
    modelValue() {
      if (this.modelValue) {
        this.handleValues()
      }
      this.$emit('update:modelValue', false)
    },
  },
  mounted() {
    this.$emit('set-step', 3)
    this.$emit('set-route', {
      previousRouteName: 'groupingVariablesAdd',
      routeParams: this.routeParams,
    })
    this.loadData()
  },
  methods: {
    setType(event) {
      this.grouping.type = parseInt(event, 10)
    },
    async loadData() {
      this.isLoading = true
      const groupingResponse = await this.fetchService.get(`protocole/${this.$route.params.id}/regroupement_essai/${this.$route.params.rid}`)
      this.grouping = groupingResponse.data
      this.modalities = this.grouping?.modalites
      this.variables = this.grouping?.variables
      this.selectedVariables = this.setSelectedVariables()
      this.grouping.type = 1
      this.$emit('set-grouping', this.grouping ?? {})

      // eslint-disable-next-line
      for (const element of this.grouping.essais) {

        // eslint-disable-next-line
        const essaiResponse = await this.fetchService.get(`essai/${element.id}`)
        const modalites = this.grouping.modalites.filter((item) => item.essais[element.id].selected === true)
        const variables = this.grouping.variables.filter((item) => item.essais[element.id].selected === true)

        const itemToAdd = {
          nom: essaiResponse?.data.nom,
          cultures: essaiResponse?.data?.protocole?.cultures,
          annee: essaiResponse?.data?.protocole?.annee_recolte,
          entite: essaiResponse?.data?.protocole?.entite?.nom,
          modalites: modalites.length,
          variables,
        }

        this.trials.push(itemToAdd)
      }

      this.isLoading = false
    },
    setSelectedVariables() {
      console.log('this.variables', this.variables)
      return this.variables.filter((variable) => (variable.essais ? Object.values(variable.essais).some((essai) => essai.selected === true) : false))
    },
    handleValues() {
      this.emitter.emit('open-loader')

      const params = {}
      params.nom = this.grouping.nom
      params.type = this.grouping.type

      if (!params.nom) {
        this.errors.nom = 'Ce champ est obligatoire'
        this.emitter.emit('alert', {
          type: 'error',
          content: 'Le champ nom est obligatoire.',
        })
        this.emitter.emit('close-loader')
      } else if (!params.type) {
        this.errors.type = 'Ce champ est obligatoire'
        this.emitter.emit('alert', {
          type: 'error',
          content: 'Le champ type d\'analyse est obligatoire.',
        })
        this.emitter.emit('close-loader')
      } else {
        const endpoint = `protocole/${this.$route.params.id}/regroupement_essai/${this.grouping.id}`

        this.fetchService.put(endpoint, params)
          .then(() => {
            const routeParams = { id: this.$route.params.id }
            this.fetchService.patch(`protocole/${this.$route.params.id}/regroupement_essai/${this.grouping.id}/statistiques`)
              .then(() => {
                this.emitter.emit('alert', {
                  type: 'success',
                  content: 'Le rapport est en cours de génération.',
                })
                this.emitter.emit('list-refresh')
                this.$router.push({ name: 'protocolEssaisGroupings', params: routeParams })
                this.emitter.emit('close-loader')
              })
          })
          .catch(() => {
            this.emitter.emit('alert', {
              type: 'error',
              content: 'Une erreur s\'est produite lors de la mise à jour du regroupement.',
            })
            this.emitter.emit('close-loader')
          })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
h2 {
  >span {
    color: $color-gray;
    font-weight: $font-weight-normal;
  }
}

h3 {
  margin-bottom: $gutter;
}

.variable {
  font-weight: $font-weight-semibold;
}

.capped-width {
  max-width: 60rem;
}
</style>
